
//zmienne statyczne po staremu do ręcznego przełączania
// const IP =            'http://localhost:3002/api_www/';
// const IP_SOCKET =     "http://localhost:3002"

 const IP = 'https://planer.artdruk.eu:3443/api_www/';
  const IP_SOCKET= "https://planer.artdruk.eu:3443"


//  const IP = process.env.IP;
//   const IP_SOCKET= process.env.IP_SOCKET;


//   Get-ChildItem -Path Env:


//env
// ustawić lokalnie zmienną env IP i IP_SOCKET
// $env:IP = "http://localhost:3002/api_www/"
// $env:IP_SOCKET =     "http://localhost:3002"

// const IP_SOCKET = process.env.IP_SOCKET || 'https://planer.artdruk.eu:3443'
// ($env:REACT_APP_NOT_SECRET_CODE = "abcdef") -and (npm start)
// ($env:IP = "http://localhost:3002/api_www/") -and( $env:IP_SOCKET = "http://localhost:3002" ) -and (npm start)
// usuwanie env
// rm env:zmienna
export  {
 IP,IP_SOCKET
}

